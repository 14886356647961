<h3>{{ getAlertMessage() }}</h3>
<mat-form-field class="w-100" floatLabel="always">
  <mat-label>Motivo de la descarga</mat-label>
  <mat-select [(ngModel)]="selectedReason">
    <mat-option *ngFor="let reason of reasons | obj2arr" [value]="reason">
      <span>{{ reason }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>

<button class="w-100" (click)="submitDownload()" [disabled]="!selectedReason" mat-raised-button color="primary"
>Descargar
</button>
