import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from '../../shared/order.service';
import { HttpClient } from '@angular/common/http';
import { PickupPointsSelectorComponent } from "../pickup-points-selector/pickup-points-selector.component";
import { PickupPoint } from "../../models/pickupPoint.model";
import { ShippingCourier, ShippingCourierService, ShippingType } from "../../models/models";

export enum Address {
  BILL = 'billingAddress',
  SHIP = 'shippingAddress'
}

export interface ProvinceModel {
  provincia_id: string;
  nombre: string;
}

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {

  address = Address;
  addressForm: FormGroup = null;
  isLoading: boolean = false;
  allProvinces: Array<ProvinceModel> = null;
  itWasPickupPoint = false;
  public readonly ShippingType = ShippingType;

  validationMessages = {
    'addressLine1': [
      { type: 'required', message: 'Dirección Requerida' }
    ],
    'city': [
      { type: 'required', message: 'Ciudad Requerida' }
    ],
    'province': [
      { type: 'required', message: 'Provincia Requerida' },
    ],
    'postalCode': [
      { type: 'required', message: 'Código Postal Requerido' },
      { type: 'minlength', message: 'Código Postal Muy Corto' },
      { type: 'maxlength', message: 'Código Postal Muy Largo' },
      { type: 'pattern', message: 'Código Postal Incorrecto' },
    ]
  };


  constructor(
    private matDialogRef: MatDialogRef<AddressModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private orderService: OrderService,
    private http: HttpClient,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    // console.log("Estos son los datos: ", this.data);

    this.createForm();
    this.loadProvinces();
    this.itWasPickupPoint = !!this.addressForm.get('pickupPointCode').value;
    if (this.itWasPickupPoint) {
      this.addressForm.get('postalCode').disable();
      this.addressForm.get('addressLine1').disable();
      this.addressForm.get('addressLine2').disable();
      this.addressForm.get('province').disable();
      this.addressForm.get('city').disable();
    }
  }

  async loadProvinces(): Promise<void> {
    await this.http
      .get <any>('./assets/data/provincias.json')
      .toPromise()
      .then((data: ProvinceModel[]) => {
        this.allProvinces = data;
      });
  }

  createForm() {
    const postalVal = [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern(/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/)
    ]

    this.addressForm = this.fb.group({
      recipient: [''],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      province: ['', Validators.required],
      provinceId: [''],//ninguno
      postalCode: ['', Validators.compose(postalVal)],
      country: [''],//ninguno
      phone: [''],//shipping
      pickupPointCode: [''],
      pickupPointName: [''],
      shippingCourier: [''],
      //Billing
      organization: [''],
      taxId: ['']
    })

    this.addressForm.patchValue(this.data.address);
  }


  inputChanges(controlName: string): void {
    // console.log(this.addressForm.controls[controlName].value);

    switch (controlName) {
      case 'postalCode':
        this.setProvince();
        break;
      default:
        break;
    }
  }


  // esto solo se activa cuando la longitud es 5
  setProvince() {
    setTimeout(() => {
      const postal: string = this.addressForm.controls['postalCode'].value;
      if (postal && postal.length == 5) {
        const pNumber = postal.split('').slice(0, 2).join("");
        const index = this.allProvinces.findIndex((prov: ProvinceModel) => prov.provincia_id === pNumber);
        if (index != -1 && this.allProvinces.length != 0) {
          this.addressForm.controls['province'].setValue(this.allProvinces[index].nombre)
          this.addressForm.controls['provinceId'].setValue(pNumber)
        }
      }
    }, 1);
  }


  //devuelve si algo ha cambiado
  checkChanges(): boolean {
    const valueForm = this.addressForm.value;
    const keyForm = Object.keys(valueForm);
    let changed: boolean = false;

    keyForm.forEach(key => {
      if (valueForm[key] !== this.data.address[key]) {
        changed = true;
      }
    });
    return changed;
  }

  cancelForm() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.addressForm.valid) {
      this.isLoading = true;
      this.addressForm.disable();
      // const mustChangeShippingService: boolean = (this.itWasPickupPoint && !this.addressForm.get('pickupPointCode').value) || (!this.itWasPickupPoint && !!this.addressForm.get('pickupPointCode').value);
      const { shippingCourier, shippingCourierService } = this.getShippingService();

      const data = {
        id: this.data.id,
        [this.data.selectedAddress]: this.addressForm.value, //{...changes} //this.addressForm.value
        shippingCourier,
        shippingCourierService,
      };

      if (this.data.selectedAddress !== Address.BILL) {
        data['shippingLabelId'] = null;
      }
      this.orderService.upsert(data).then(() => {
        this.isLoading = false;
        this.matDialogRef.close(this.data);// retorna data aquí
      }).catch((err) => {
        console.error("Error encontrado: ", err);
      })
        .finally(() => {
          this.addressForm.enable();
        })
    }

  }

  openPickupPointsSelector() {
    const dialogRef = this.dialog.open(PickupPointsSelectorComponent, {
      disableClose: true,
      data: {
        order: this.data.order
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const point: PickupPoint = res.pointSelected;
        this.addressForm.get('addressLine1').setValue(point.address);
        this.addressForm.get('addressLine2').setValue('');
        this.addressForm.get('city').setValue(point.city);
        this.addressForm.get('postalCode').setValue(point.postalCode);
        this.addressForm.get('pickupPointCode').setValue(point.id)
        this.addressForm.get('pickupPointName').setValue(point.name)
        this.addressForm.get('shippingCourier').setValue(point.company)
        this.setProvince();

        this.addressForm.get('postalCode').disable();
        this.addressForm.get('addressLine1').disable();
        this.addressForm.get('addressLine2').disable();
        this.addressForm.get('province').disable();
        this.addressForm.get('city').disable();
      }
    });
  }

  clearPickupPoint() {
    this.addressForm.get('addressLine1').setValue('');
    this.addressForm.get('addressLine2').setValue('');
    this.addressForm.get('city').setValue('');
    this.addressForm.get('postalCode').setValue('');
    this.addressForm.get('pickupPointCode').setValue('');
    this.addressForm.get('pickupPointName').setValue('');
    this.addressForm.get('province').setValue('');
    this.addressForm.get('shippingCourier').setValue('');

    this.addressForm.get('postalCode').enable();
    this.addressForm.get('addressLine1').enable();
    this.addressForm.get('addressLine2').enable();
    this.addressForm.get('province').enable();
    this.addressForm.get('city').enable();
  }

  private getShippingService() {
    let shippingCourier = '';
    let shippingCourierService = '';

    switch (this.addressForm.get('shippingCourier').value) {
      case ShippingCourier.CEX:
        shippingCourier = ShippingCourier.CEX;
        shippingCourierService = ShippingCourierService.CEX_PAQ_24;
        break;
      case ShippingCourier.GLS:
        shippingCourier = ShippingCourier.GLS;
        shippingCourierService = ShippingCourierService.GLS_EXPRESS;
        break;
      case ShippingCourier.MRW:
        shippingCourier = ShippingCourier.MRW;
        shippingCourierService = '';
        break;
      case ShippingCourier.CORREOS:
        shippingCourier = ShippingCourier.CORREOS;
        shippingCourierService = this.addressForm.get('pickupPointCode').value.length === 9 ? 'correos_premium_citypaq' : 'correos_premium_oficina';
        break;
      default:
        shippingCourier = '';
        shippingCourierService = '';
        break;
    }
    return { shippingCourier, shippingCourierService };
  }

}
