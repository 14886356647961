import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderProcessStatus, OrderTypeStatus } from "../../models/models";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-document-download-request-modal',
  templateUrl: './document-download-request-modal.component.html',
  styleUrls: ['./document-download-request-modal.component.scss']
})
export class DocumentDownloadRequestModalComponent implements OnInit {

  reasons = [
    'Gestionar una incidencia',
    'Imprimir manualmente'
  ];
  selectedReason = null;
  isFile = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private dialogRef: MatDialogRef<DocumentDownloadRequestModalComponent>,) {
  }

  ngOnInit(): void {
    this.isFile = !!this.data.downloadRequest.file;
  }

  getAlertMessage() {
    if (this.data.downloadRequest.archive) {
      return `Estás a punto de descargar el grupo ${this.data.downloadRequest.archive.printGroupId + 1}.`;
    } else {

    }
  }

  submitDownload() {
    const fileUrl = this.isFile
      ? this.data.downloadRequest.file.originalFile.url
      : this.data.downloadRequest.archive.archiveFile.url;

    // Solicitar el archivo como blob
    this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob) => {
      // Crear un enlace temporal para la descarga
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;

      // Forzar descarga asignando un nombre al archivo
      link.download = this.isFile ? this.data.downloadRequest.file.originalFile.name : this.data.downloadRequest.archive.archiveFile.name;

      // Simular clic y limpiar recursos
      link.click();
      window.URL.revokeObjectURL(url); // Liberar memoria
      const resultFile = this.isFile ? this.data.downloadRequest.file.printFile : {
        ...this.data.downloadRequest.archive.archiveFile,
        printGroup: this.data.downloadRequest.archive.printGroupId + 1
      };
      this.dialogRef.close({ reason: this.selectedReason, downloadRequest: resultFile });
    });
  }
}
